import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SelectWrapper = styled.div`
  position: relative;

  .select-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .select-item {
    cursor: pointer;
  }

  .select-button {
    cursor: pointer;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }
`;

const Select = ({ itemList, activeItem, setSelectedItemToParent }) => {
  const [selectedItem, setSelectedItem] = useState(activeItem);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelect = () => setIsOpen(!isOpen);

  return (
    <SelectWrapper>
      <button className="select-button" type="button" onClick={toggleSelect} onKeyDown={toggleSelect}>
        {selectedItem.selectValue}
      </button>
      {isOpen && (
        <div className="select-list">
          {itemList.map(item => (
            <a href="/register/?register_type=onsitegames"
              key={item.id}
              className="select-item"
              onClick={(e) => {
                setSelectedItem(item);
                setSelectedItemToParent(item);
                setIsOpen(false);
                e.preventDefault();
              }}
            >
              {item.selectValue}
            </a>
          ))}
        </div>
      )}
    </SelectWrapper>
  );
};

Select.propTypes = {
  itemList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      selectValue: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  activeItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selectValue: PropTypes.node.isRequired,
  }).isRequired,
  setSelectedItemToParent: PropTypes.func.isRequired,
};

export default Select;
