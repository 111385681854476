import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import User from '../../../../../icons/svg/User';

const UserIcon = ({ author, emoji }) => (
  <StaticQuery
    query=
      {graphql`
        query {
          star: file(relativePath: { eq: "freespin/user-icon-new.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          smiley: file(relativePath: { eq: "freespin/smiley.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          crazy: file(relativePath: { eq: "freespin/crazy.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          cowboy: file(relativePath: { eq: "freespin/cowboy.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          devil: file(relativePath: { eq: "freespin/devil.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          love: file(relativePath: { eq: "freespin/love.png" }) {
            childImageSharp {
              fixed(width: 32) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      `}
    render={data => {
      let emojiObject;
      switch (emoji) {
        case 'love':
          emojiObject = data.love;
          break;
        case 'devil':
          emojiObject = data.devil;
          break;
        case 'cowboy':
          emojiObject = data.cowboy;
          break;
        case 'crazy':
          emojiObject = data.crazy;
          break;
        case 'smiley':
          emojiObject = data.crazy;
          break;
        default:
          emojiObject = null;
      }
      return (
        <div className="star-holder">
          {(emoji && emojiObject) ? <div className="emoji-wrap"><Img alt={author} fixed={emojiObject.childImageSharp.fixed} fadeIn={false} loading='eager' /></div>
            : <User idA={author.toLowerCase() + Math.random()*100 + "-a"} idB={author.toLowerCase() + Math.random()*100 + "-b"}/>
          }
        </div>
      )
    }
    }
  />
);

UserIcon.propTypes = {
  author: PropTypes.string.isRequired,
  emoji: PropTypes.string
};

export default UserIcon;