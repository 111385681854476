import React from "react"

const Enjoy = ({ width = "74", height = "63", viewBox = "0 0 74 63" }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       viewBox={viewBox}
       width={width}
       height={height}>
    <defs>
      <filter id="ao1kxttp0a" width="121.4%" height="143.5%" x="-10.7%" y="-15.5%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.399776005 0" />
      </filter>
      <filter id="ynmjmenn9c" width="127.4%" height="125.5%" x="-13.7%" y="-9.1%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.399776005 0" />
      </filter>
      <path id="2zjlze1a6b"
            d="M61.648 11.32c2.165.004 3.919 1.76 3.92 3.924v13.617c0 .838-.68 1.518-1.518 1.518-.839 0-1.518-.68-1.518-1.518V15.244c0-.49-.397-.887-.887-.887H3.923c-.49 0-.886.397-.886.887V39.58c0 .49.397.887.886.887h57.725c.49 0 .887-.397.887-.887v-4.105c0-.839.68-1.519 1.518-1.519s1.518.68 1.518 1.519v4.105c-.001 2.166-1.757 3.922-3.923 3.924H3.923C1.757 43.502.002 41.746 0 39.58V15.244c.002-2.166 1.757-3.922 3.923-3.923h57.725zm-45.836 7.028c1.929 0 3.687 1.106 4.523 2.844.836 1.739.602 3.802-.603 5.31 1.893 1.448 2.652 3.941 1.887 6.199-.765 2.257-2.883 3.776-5.266 3.776h-4.756c-.838 0-1.518-.68-1.518-1.519V19.866c0-.839.68-1.518 1.518-1.518h4.215zm33.358.036c2.294 0 4.341 1.437 5.12 3.594.78 2.157.122 4.57-1.643 6.035.195.254.368.523.52.805.567 1.076.878 2.268.91 3.483v2.64c-.005.827-.672 1.498-1.5 1.508-.838 0-1.518-.68-1.518-1.518v-2.639l-.008-.175c-.002-.033-.006-.068-.01-.105l-.033-.24c-.153-.944-.677-2.517-1.862-2.517H45.83v5.67c0 .839-.68 1.518-1.519 1.518-.838 0-1.518-.68-1.518-1.518V19.902c0-.838.68-1.518 1.518-1.518h4.86zm-17.33.161c.694 0 1.32.418 1.585 1.06l6.265 14.57c.205.47.159 1.011-.122 1.44-.281.428-.76.686-1.272.686v-.022c-.605.001-1.152-.357-1.394-.91l-1.883-4.374h-6.392l-1.898 4.38c-.335.77-1.231 1.121-2.001.786-.77-.336-1.122-1.231-.787-2.001l2.168-5.002c.043-.2.125-.389.243-.555l3.9-9.004c.248-.595.806-.996 1.44-1.048l.148-.006zM16.536 28.39h-3.418v5.05h3.235c.923.032 1.79-.442 2.26-1.237.471-.794.471-1.782 0-2.576-.408-.689-1.113-1.137-1.895-1.223l-.182-.014zm15.298-4.804l-1.892 4.364h3.771l-1.88-4.364zm17.53-2.174h-3.516v4.813h3.34c.88.031 1.708-.42 2.157-1.178.45-.758.45-1.7 0-2.457-.42-.707-1.168-1.148-1.981-1.178zm-33.552-.028h-2.694v3.97h2.694c1.066-.042 1.908-.919 1.908-1.985 0-1.067-.842-1.943-1.908-1.985z" />
      <path id="tmhtd4tkjd"
            d="M52.529 54.961H13.033c-3.223-.005-5.834-2.616-5.84-5.84V47.26c0-.839.68-1.518 1.52-1.518.838 0 1.518.68 1.518 1.518v1.861c.001 1.548 1.255 2.802 2.802 2.803H52.51c1.548-.001 2.802-1.255 2.803-2.803V47.26c0-.839.68-1.518 1.519-1.518.838 0 1.518.68 1.518 1.518v1.861c-.005 3.216-2.605 5.825-5.821 5.84zm4.32-45.742c-.838 0-1.517-.68-1.517-1.518V5.84c-.002-1.548-1.256-2.802-2.803-2.803H13.033c-1.547.001-2.8 1.255-2.802 2.803V7.7c0 .839-.68 1.518-1.519 1.518-.838 0-1.518-.68-1.518-1.518V5.84C7.199 2.617 9.81.005 13.034 0H52.51c3.223.005 5.835 2.617 5.84 5.84V7.7c0 .832-.669 1.508-1.5 1.518z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g transform="translate(-1206 -703) translate(1210 705)">
            <use fill="#000" filter="url(#ao1kxttp0a)" xlinkHref="#2zjlze1a6b" />
            <use fill="#FF7800" xlinkHref="#2zjlze1a6b" />
          </g>
          <g transform="translate(-1206 -703) translate(1210 705)">
            <use fill="#000" filter="url(#ynmjmenn9c)" xlinkHref="#tmhtd4tkjd" />
            <use fill="#ECC45A" xlinkHref="#tmhtd4tkjd" />
          </g>
        </g>
      </g>
    </g>
  </svg>

)

export default Enjoy
