import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Diamond from "./Diamond"
import styled from "@emotion/styled"
import { breakpoints, color } from "../../../utils/style"

const TricksToWinWrap = styled("div")`
  .title-block {
    padding: 70px 0 0;
    position: relative;
  }
  h2 {
    font-size: 43px;
    line-height: 1.3;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  @media (max-width: ${breakpoints.md}) {
    h2 {
      font-size: 22px;
    }
  }  
`

const SlidesList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  
  .slide-item {
    flex-basis: 32%;
    background: ${color.transparentWhite1};
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    display: flex;
    flex-flow: row-wrap;
    align-items: center;
    .img-wrap {
      width: 70px;
    }
    
    .text-wrap {
      flex: 1;
      padding: 10px 20px;
    }
    
    .h4 {
      color: ${color.gold};
      font-size: 16px;
      font-weight: 700;
    }
    
    .text {
      font-size: 13px;
      margin-top: 10px;
    }
    
    @media (max-width: ${breakpoints.md}) {
      flex-basis: 100%;
    }  
  }
`

const TricksToWin = ({ h2, slides }) => {

  return (
    <TricksToWinWrap>
      {h2 && <div className="title-block">
        <Diamond />
        <h2 className="h3">{h2}</h2>
      </div>}
      {slides.length > 0 && (
        <SlidesList>
          {slides.map(item => (
            <div key={item.id} className="slide-item">
              {item?.localFile?.childImageSharp?.fluid && (
                <div className="img-wrap">
                  <Img
                    fluid={item.localFile.childImageSharp.fluid}
                    alt={item.altText ? item.altText : "Golden Slot"}
                    fadeIn={false}
                    loading="eager"
                  />
                </div>
              )}
              {item.acf?.slide_title &&
                <div className="text-wrap">
                  <div className="h4">{item.acf.slide_title}</div>
                  {item.acf?.slide_description &&
                    <div className="text"
                         dangerouslySetInnerHTML={{ __html: item.acf.slide_description }} />
                  }
                </div>
              }
            </div>
          ))}
        </SlidesList>
      )}
    </TricksToWinWrap>
  )
}

export default TricksToWin

TricksToWin.propTypes = {
  h2: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      acf: PropTypes.shape({
        slide_description: PropTypes.string,
        slide_title: PropTypes.string
      }),
      alt_text: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string
    })
  )
}
