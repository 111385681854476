import React from "react";

const Step = ({
  width = "202",
  className = "",
  height = "120",
  viewBox = "0 0 202 120",
  id = "step-icon-a",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient id="d0tv7dgmia" x1="153.275%" x2="81.157%" y1="80.457%" y2="27.437%">
        <stop offset="0%" stopColor="#ECC45A"/>
        <stop offset="100%" stopColor="#ECC45A" stopOpacity="0"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" opacity=".4">
      <g fill="url(#d0tv7dgmia)" transform="translate(-975 -673)">
        <g>
          <path d="M784 1l40.603 54.204c2.13 2.843 2.13 6.75 0 9.592L784 119H626V1h158z" transform="translate(349 672)"/>
        </g>
      </g>
    </g>
  </svg>
);

export default Step;
