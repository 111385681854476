import React from 'react'

const timerContext = React.createContext({
  initialTime: 300000,
  timeLeft: 300000,
  renderer: () => {},
  ticker: () => {}
});

export default timerContext;
