import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import Img from 'gatsby-image/withIEPolyfill';
import GetWindowWidth from '../../shared/GetWindowWidth';
import Select from '../../shared/Select';
import ScrollTo from '../../shared/ScrollTo';

const GameProvidersWrapper = styled.div`
  padding: 10px 0;
  margin-bottom: 20px;
`;

const GameList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  padding: 20px;

  .gamelist-item {
    display: block;
    width: calc(100% / 6);
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 0;
  }

  .gamelist-link {
    display: block;
    padding: 10px;
    border-radius: 8px;

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .gamelist-item {
      width: calc(100% / 4);
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const GameSelect = styled.div`
  padding: 15px;
  background-color: #242C2D;
  border-radius: 8px;

  .select-button {
    position: relative;
    display: block;
    width: 100%;
    height: 54px;
    padding: 6px 20px;
    margin-bottom: 20px;
    background-color: #303738;
    border: none;
    border-radius: 8px;
    -webkit-appearance: none;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 6px);
      left: 26px;
      display: block;
      width: 10px;
      height: 10px;
      border-right: 2px solid ${color.gold};
      border-bottom: 2px solid ${color.gold};
      transform: rotate(45deg);
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 160px;
    height: 40px;
    margin-right: auto;
    margin-left: auto;
  }

  .select-list {
    padding: 10px 20px;
    list-style: none;
    background-color: #303738;
    border-radius: 0 0 6px 6px;
  }

  .select-item {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid ${color.transparentWhite1};

    &:last-child {
      border-bottom: none;
    }
  }

  .game-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    font: inherit;
    font-size: 15px;
    font-weight: 500;
    color: ${color.veryDarkOrange};

    line-height: 48px;
    text-decoration: none;
    background-color: ${color.transparent};
    border: 1px solid ${color.veryDarkOrange};
    border-radius: 500px;
  }
`;

const GameProviders = ({ gameProviders }) => {
  const windowWidth = GetWindowWidth();
  const allGameProviders = gameProviders.map(item => ({
    ...item,
    selectValue: (
      <Img
        fluid={item.localFile.childImageSharp.fluid}
        objectFit="scale-down"
        alt={item.alt_text ? item.alt_text : 'Game Provider'}
        fadeIn={false} loading='eager'
      />
    ),
  }));
  const [activeItem, setActiveItem] = useState(allGameProviders[0]);

  const onSelect = item => {
    const parent = document.getElementById('gameSelect');

    setActiveItem(item);
    ScrollTo(parent);
  };

  const gameList =
    windowWidth > 600 ? (
      <GameList>
        {allGameProviders.map(item => (
          <li key={item.id} className="gamelist-item">
            <a className="gamelist-link" href={item.acf.slide_url}>
              {item.selectValue}
            </a>
          </li>
        ))}
      </GameList>
    ) : (
      <GameSelect id="gameSelect">
        <Select
          itemList={allGameProviders}
          activeItem={activeItem}
          setSelectedItemToParent={onSelect}
        />
        <a href={activeItem.acf.slide_url} className="game-link button transparent-button-gold">
          สมัครสมาชิก
        </a>
      </GameSelect>
    );

  return <GameProvidersWrapper>{gameList}</GameProvidersWrapper>;
};

GameProviders.propTypes = {
  gameProviders: PropTypes.arrayOf(
    PropTypes.shape({
      acf: PropTypes.object.isRequired,
      id: PropTypes.string.isRequired,
      localFile: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
};

export default GameProviders;
