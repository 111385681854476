import React from "react"

const Deposit = ({ width = "73", height = "68", viewBox = "0 0 73 68" }) => (

  <svg xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"
       viewBox={viewBox}
       width={width}
       height={height}>
    <defs>
      <filter id="kfl2shrz8a" width="142.3%" height="123.6%" x="-21.2%" y="-8.4%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.399776005 0" />
      </filter>
      <filter id="nodfmqtobc" width="132.4%" height="121.9%" x="-16.2%" y="-7.8%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.399776005 0" />
      </filter>
      <path id="b3m3f8ri3b"
            d="M35.415 59.41h-5.793c-1.19 0-2.154-.965-2.154-2.155v-2.557c-4.366-1.307-7.983-4.385-9.973-8.486-.647-1.35-.716-2.905-.19-4.307.55-1.468 1.68-2.646 3.123-3.259 2.935-1.277 6.421 0 7.769 2.806.817 1.636 2.498 2.66 4.326 2.637 2.61 0 4.735-1.997 4.735-4.452 0-.763 0-2.354-6.753-4.735C26.535 33.505 16 29.794 16 19.468c0-.308 0-.616.03-.923.033-.55.356-1.04.849-1.287.492-.247 1.078-.212 1.538.09.46.303.723.829.69 1.378 0 .25-.024.502-.024.745 0 8.147 8.41 11.11 12.45 12.532 7.31 2.575 8.809 4.923 8.809 7.637 0 4.15-3.505 7.53-7.813 7.53-3.013.017-5.771-1.687-7.104-4.388-.707-1.368-2.36-1.944-3.763-1.311-.677.283-1.208.83-1.471 1.514-.233.625-.202 1.318.086 1.92 1.73 3.54 4.946 6.129 8.775 7.061.883.224 1.5 1.019 1.499 1.93v2.46h3.954v-2.46c.001-.91.62-1.703 1.501-1.927 5.868-1.495 9.966-6.563 9.966-12.329.008-.875-.096-1.748-.307-2.597-.152-.537 0-1.113.395-1.507.395-.393.972-.542 1.508-.39.536.154.947.585 1.075 1.128.28 1.1.421 2.23.419 3.366 0 6.902-4.674 12.988-11.468 15.077v2.554c-.004.573-.236 1.12-.645 1.522-.409.4-.96.623-1.534.616zm8.431-26.77c-.404 0-.792-.16-1.08-.443-2.932-2.89-7.2-4.394-9.252-5.114-6.422-2.258-8.806-4.335-8.806-7.63 0-4.151 3.504-7.53 7.812-7.53 2.871-.024 5.526 1.524 6.92 4.034.778 1.333 2.462 1.828 3.837 1.126.655-.315 1.153-.884 1.378-1.575.2-.639.131-1.33-.19-1.917-1.814-3.264-4.912-5.619-8.542-6.493-.839-.205-1.428-.957-1.428-1.821v-2.2h-3.953v2.206c0 .862-.588 1.614-1.425 1.822-3.608.875-6.691 3.208-8.514 6.443-.41.744-1.346 1.015-2.09.604-.745-.41-1.016-1.346-.605-2.09 2.074-3.701 5.498-6.457 9.557-7.693V2.154c0-1.19.964-2.154 2.153-2.154h5.794c1.19 0 2.154.964 2.154 2.154V4.36c4.086 1.236 7.528 4.018 9.594 7.754.725 1.317.878 2.875.422 4.308-.47 1.49-1.535 2.721-2.942 3.403-2.857 1.425-6.33.367-7.908-2.41-.856-1.52-2.474-2.45-4.218-2.427-2.612 0-4.736 1.997-4.736 4.452 0 .822 0 2.354 6.77 4.732 2.261.797 6.966 2.462 10.387 5.828.445.438.58 1.102.344 1.68-.237.577-.8.954-1.424.954l-.009.006z" />
      <path id="4dpm4jszkd"
            d="M16.84 64.961c-3.223-.005-5.835-2.616-5.84-5.84V53.26c0-.839.68-1.518 1.518-1.518.839 0 1.519.68 1.519 1.518v5.861c.001 1.548 1.255 2.802 2.803 2.803h31.477c1.547-.001 2.8-1.255 2.802-2.803V53.26c0-.839.68-1.518 1.519-1.518.838 0 1.518.68 1.518 1.518v5.861c-.005 3.216-2.605 5.825-5.821 5.84H16.84zm34.298-51.26V6.84c-.002-1.548-1.256-2.802-2.803-2.803H16.84c-1.548.001-2.802 1.255-2.803 2.803V13.7c0 .839-.68 1.518-1.519 1.518-.838 0-1.518-.68-1.518-1.518V6.84c.005-3.223 2.617-5.835 5.84-5.84h31.477c3.223.005 5.834 2.617 5.84 5.84V13.7c0 .832-.67 1.508-1.501 1.518-.839 0-1.518-.68-1.518-1.518z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g transform="translate(-807 -700) translate(813 702)">
            <use fill="#000" filter="url(#kfl2shrz8a)" xlinkHref="#b3m3f8ri3b" />
            <use fill="#FF7800" xlinkHref="#b3m3f8ri3b" />
          </g>
          <g transform="translate(-807 -700) translate(813 702) rotate(90 32.578 32.98)">
            <use fill="#000" filter="url(#nodfmqtobc)" xlinkHref="#4dpm4jszkd" />
            <use fill="#ECC45A" xlinkHref="#4dpm4jszkd" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Deposit
