import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import { Slick, breakpoints, ContentStyles, wrapper } from "../utils/style"
import Layout from "../components/shared/layout"
import SEO from "../components/SEO/SEO"
import styled from "@emotion/styled"
import withLocation from "../components/shared/withLocation"
import Steps from "../components/Content/Home/Steps"
import PlayForFree from "../components/Content/Home/PlayForFree"
import GameProviders from "../components/Content/Home/GameProviders"
import ChooseGameImg from "../components/Content/Home/ChooseGameImg"
import GetWindowWidth from "../components/shared/GetWindowWidth"
import Parallax from "../components/shared/Parallax"
import WithdrawCommentsStaticLoadmore from "../components/Content/Withdraw/Comments/CommentsStaticLoadmore"
import Demo15Games from "../components/Content/Home/SlotGames"
import TypeOfGame from "../components/Content/Home/TypeOfGame"
import FourImages from "../components/Content/Home/FourImages"
import TricksToWin from "../components/Content/Home/TricksToWin"
import WhyUs from "../components/Content/Home/WhyUs"
import { useHasBeenVisible } from "../components/shared/useVisibility"

const WrapperDesktop = styled("div")`
  ${wrapper};
  background-image: radial-gradient(
    circle at 50% 31%,
    rgba(236, 196, 90, 0.19),
    rgba(236, 196, 90, 0) 47%
  );
  .non-seo-content {
    height: 5150px;
  }
  @media (max-width: ${breakpoints.md}) {
    .non-seo-content {
      height: 7500px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .non-seo-content {
      height: 10500px;
    }
  }
`
const ContentWrapperSimple = styled("div")`
  margin-bottom: 40px;
  ${ContentStyles}
`

const SlickWrapper = styled("div")`
  padding-bottom: 5px;
  max-width: 1224px;
  margin: 0 auto;
  .slick-dots {
    bottom: 10px;
  }
  .desktop-view {
    margin: 0 -345px;
    .slick-slide {
      padding: 0 30px;
      opacity: 0.3;
    }
    .slick-active {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      border-radius: 30px;
    }
    &.no-padding {
      margin: 0;
    }
  }
  .mobile-view {
    display: none;

    .slick-slide {
      padding: 0 5px;
      opacity: 0.5;
    }
    .slick-active {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
  }
  ${Slick};
  @media (max-width: ${breakpoints.xl}) {
    .desktop-view {
      margin: 0 -325px;
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    .desktop-view {
      margin: 0 -320px;
      .slick-slide {
        padding: 0 10px;
      }
    }
  }
  @media (max-width: 1000px) {
    .desktop-view {
      margin: 0 -300px;

      .slick-slide {
        padding: 0 5px;
      }
    }
  }
  @media (max-width: 750px) {
    .mobile-view {
      display: block;
    }
    .desktop-view {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .slick-dots {
      bottom: 0;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .slick-prev,
    .slick-next {
      display: none;
    }
  }
`

const CardsWrapper = styled("div")`
  width: 100%;
  max-width: 1244px;
  margin: 55px auto 0;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .h1 {
    margin-top: 0;
    text-transform: uppercase;
  }
  @media (max-width: ${breakpoints.xs}) {
    span {
      font-size: 22.8px;
      max-width: 280px;
      text-align: center;
      margin-top: -48px;
    }
  }
`

const WrapperPadded = styled("div")`
  ${wrapper};
  margin: 60px auto;
`

const BannerLink = styled("a")`
  max-width: 1224px;
  display: block;
  margin: 0 auto;
`

const BannerWrap = styled("div")`
  max-width: 1224px;
  margin: 0 auto;
  padding: 5px 0;

  @media (max-width: ${breakpoints.lg}) {
    padding: 5px 20px;
  }
`

const HomePage = ({ data }) => {
  const halfPage = useRef()
  const hasScrolled = useHasBeenVisible(halfPage)

  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 750
  const homePageNode = data.allWordpressPage.edges[0].node
  const featuredMedia = homePageNode.featured_media
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.OWNER
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : ""
  const socialImage = homePageNode.featured_media?.localFile?.publicURL
  const slider = homePageNode.acf.top_slider
  const sliderMobile = homePageNode.acf.top_slider_mobile
  const showSlider = slider && slider.length > 0
  const showSliderMobile = sliderMobile && sliderMobile.length > 0
  const showPlayForFree = homePageNode.acf.play_for_free
  const playForFreeIframe = homePageNode?.acf?.play_for_free_video_file
  const playForFreeTitle = homePageNode.acf.play_for_free_title
  const playForFreeDesc = homePageNode.acf?.play_for_free_desc
  const playForFreeVideoCover = homePageNode.acf.play_for_free_video_cover
  const demoGamesDesc = homePageNode.acf?.demo_games_desc
  const popularGoldenslotGames = homePageNode.acf?.popular_goldenslot_games
  const popularGoldenslotDesc = homePageNode.acf?.popular_goldenslot_desc
  const gameTypesBottom = homePageNode.acf?.game_types_bottom
  const gameTypesDesc = homePageNode.acf?.game_types_desc
  const tricksToWin = homePageNode.acf?.tricks_to_win
  const whyGoldenSlot = homePageNode.acf?.why_goldenslot
  const whyGoldenslotDesc = homePageNode.acf?.why_goldenslot_desc
  const gameProviderDesc = homePageNode.acf?.game_provider_desc
  const showGameProviders = homePageNode.acf.game_providers.length > 0
  const sliderSettings = {
    dots: true,
    arrows: false,
    centerMode: true,
    centerPadding: "15px",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  }
  const mainBannerLink = slider[0].acf ? slider[0].acf.slide_url : ""
  const firstSlide = slider[0]
  const bannerCondition =
    (firstSlide.acf && firstSlide.acf.slide_url.indexOf("http") !== -1) ||
    (firstSlide.acf && firstSlide.acf.slide_url.indexOf("#") !== -1)
  const demoGames = homePageNode.acf.demo_games_15
  const gameTypes = homePageNode.acf.game_types
  const gameTypesHoverIcons = homePageNode.acf.games_type_hover_icons

  const accordionListener = event => {
    if (event.target.classList.contains("accordion-title")) {
      let parentEl = event.target.parentNode
      if (parentEl.classList.contains("accordion-open")) {
        parentEl.classList.remove("accordion-open")
      } else {
        parentEl.classList.add("accordion-open")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", accordionListener)
    return () => {
      window.removeEventListener("click", accordionListener)
    }
  })

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        showSchema={true}
        socialImage={socialImage}
      />
      <Parallax />
      <div id="home-content-all" className="with-overflow-slider">
        {showSlider && (
          <SlickWrapper>
            <div className="desktop-view no-padding">
              <BannerWrap>
                <BannerLink
                  href={
                    bannerCondition
                      ? mainBannerLink
                      : `${process.env.URL}` + mainBannerLink
                  }
                >
                  <Img
                    fluid={firstSlide.localFile.childImageSharp.fluid}
                    alt={firstSlide.alt_text ? firstSlide.alt_text : "slide"}
                    fadeIn={false}
                    loading="eager"
                  />
                </BannerLink>
              </BannerWrap>
            </div>
            {showSliderMobile && !isDesktop && (
              <div className="mobile-view">
                <Slider {...sliderSettings}>
                  <BannerLink
                    href={
                      bannerCondition
                        ? mainBannerLink
                        : `${process.env.URL}` + mainBannerLink
                    }
                  >
                    <Img
                      fluid={firstSlide.localFile.childImageSharp.fluid}
                      alt={firstSlide.alt_text ? firstSlide.alt_text : "slide"}
                      fadeIn={false}
                      loading="eager"
                    />
                  </BannerLink>
                </Slider>
              </div>
            )}
          </SlickWrapper>
        )}

        {featuredMedia && featuredMedia.localFile && !showSlider && (
          <div>
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={homePageNode.featured_media.alt_text}
              fadeIn={false}
              loading="eager"
            />
          </div>
        )}

        <WrapperDesktop>
          <Steps />
          {showPlayForFree && (
            <PlayForFree
              iframe={playForFreeIframe.source_url}
              title={playForFreeTitle}
              video_cover={playForFreeVideoCover}
              play_free={showPlayForFree}
            />
          )}

          {hasScrolled ? (
            <div className={"non-seo-content-loaded"}>
              {playForFreeDesc && (
                <ContentWrapperSimple
                  dangerouslySetInnerHTML={{ __html: playForFreeDesc }}
                />
              )}

              <Demo15Games duration={300000} slotSlides={demoGames} />
              {demoGamesDesc && (
                <ContentWrapperSimple
                  dangerouslySetInnerHTML={{ __html: demoGamesDesc }}
                />
              )}
              {popularGoldenslotGames && popularGoldenslotGames.length && (
                <FourImages
                  slides={popularGoldenslotGames}
                  h2="สล็อตออนไลน์ ยอดฮิต ทำกำไรได้เร็ว"
                />
              )}
              {popularGoldenslotDesc && (
                <ContentWrapperSimple
                  dangerouslySetInnerHTML={{ __html: popularGoldenslotDesc }}
                />
              )}
              {tricksToWin && tricksToWin.length && (
                <TricksToWin
                  slides={tricksToWin}
                  h2="เล่นสล็อตออนไลน์ ยังไงให้ได้เงิน"
                />
              )}
              <TypeOfGame
                typesList={gameTypes}
                iconsList={gameTypesHoverIcons}
              />
              {gameTypesBottom && gameTypesBottom.length && (
                <FourImages slides={gameTypesBottom} />
              )}
              {gameTypesDesc && (
                <ContentWrapperSimple
                  dangerouslySetInnerHTML={{ __html: gameTypesDesc }}
                />
              )}

              <CardsWrapper>
                <ChooseGameImg />
                <h2 className="h1 rich-design a-center">
                  ค่าย คาสิโนออนไลน์ และพาร์ทเนอร์หลักของ
                </h2>
              </CardsWrapper>
              {showGameProviders && (
                <GameProviders
                  gameProviders={homePageNode.acf.game_providers}
                />
              )}
              {gameProviderDesc && (
                <ContentWrapperSimple
                  dangerouslySetInnerHTML={{ __html: gameProviderDesc }}
                />
              )}
              {whyGoldenSlot && whyGoldenSlot.length && (
                <WhyUs
                  slides={whyGoldenSlot}
                  h2="จุดเด่น สล็อตออนไลน์ และคาสิโนออนไลน์ ที่ดีที่สุดในเอเชีย"
                  text={whyGoldenslotDesc ?? ""}
                />
              )}
            </div>
          ) : (
            <div className={"non-seo-content"} ref={halfPage} />
          )}

          {homePageNode.content && (
            <ContentWrapperSimple>
              <div dangerouslySetInnerHTML={{ __html: homePageNode.content }} />
            </ContentWrapperSimple>
          )}
        </WrapperDesktop>

        <WrapperPadded>
          <WithdrawCommentsStaticLoadmore />
        </WrapperPadded>
      </div>
    </Layout>
  )
}

export default withLocation(HomePage)

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home-page" } }) {
      edges {
        node {
          title
          content
          wordpress_id
          featured_media {
            alt_text
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            top_slider {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1224) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            top_slider_mobile {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            play_for_free_title
            play_for_free_desc
            play_for_free_video_file {
              link
              source_url
            }
            play_for_free_video_cover {
              alt_text
              title
              path
              localFile {
                childImageSharp {
                  fluid(maxWidth: 392) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            play_for_free {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            demo_games_15 {
              alt_text
              title
              id
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 225) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            demo_games_desc
            game_provider_desc
            game_types {
              alt_text
              title
              id
              acf {
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 390) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            games_type_hover_icons {
              alt_text
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 60) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            game_types_bottom {
              alt_text
              title
              id
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 287) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            game_types_desc
            popular_goldenslot_games {
              alt_text
              title
              id
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 287) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            popular_goldenslot_desc
            tricks_to_win {
              alt_text
              title
              id
              acf {
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 70) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            game_providers {
              alt_text
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 160) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              acf {
                slide_url
              }
            }
            why_goldenslot {
              alt_text
              title
              id
              acf {
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 96) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            why_goldenslot_desc
          }
        }
      }
    }
  }
`
