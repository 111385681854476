import React from "react";

const Comment = ({
  width = "46",
  className = "",
  height = "42",
  viewBox = "0 0 46 42",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        id="commenta"
        width="137.5%"
        height="141.3%"
        x="-18.8%"
        y="-14.7%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.399776005 0"
        />
      </filter>
      <path
        id="commentb"
        d="M32.167.038c2.755 0 5.01 2.16 5.123 4.857l.005.214v16.365c0 2.724-2.184 4.953-4.911 5.066l-.217.004H15.901L5.109 33.97v-7.426c-2.747-.01-4.991-2.166-5.105-4.857L0 21.474V5.109C0 2.384 2.184.155 4.91.043l.217-.005h27.04zm0 2.162H5.127C3.563 2.2 2.28 3.413 2.19 4.938l-.005.171v16.365c0 1.546 1.227 2.815 2.77 2.904l.172.005h2.167v5.45l7.921-5.45h16.952c1.565 0 2.848-1.214 2.937-2.739l.005-.17V5.109c0-1.547-1.227-2.816-2.77-2.905l-.172-.004zm-4.854 14.695v2.16H9.982v-2.16h17.33zm0-4.61v2.16H9.982v-2.16h17.33zm0-4.61v2.16H9.982v-2.16h17.33z"
      />
    </defs>
    <g transform="matrix(-1 0 0 1 42 2)" fillRule="nonzero" fill="none">
      <use fill="#000" filter="url(#commenta)" xlinkHref="#commentb" />
      <use fill="#FFC874" xlinkHref="#commentb" />
    </g>
  </svg>
);

export default Comment;
