import React from 'react';
import IcomoonIcon from '../../../shared/IcomoonIcon';
import { color } from '../../../../utils/style';

const Stars = ({ n, cssClass }) => {
  const count = +n;
  return (
    <div className={cssClass}>
      { function() {
        let stars = [];
        for (let i=0; i<count; i++) {
          stars.push(<span key={'Star' + i + n + Math.random()*i*10}><IcomoonIcon icon="star-filled" color={color.gold} size={12}/></span>)
        }
        return stars;
      }()}

    </div>
  )
};

export default Stars;