import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/style"

const ImgContainer = styled("div")`
  position: absolute;
  width: 168px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  z-index: -1;
  opacity: 0.9;
  @media (max-width: ${breakpoints.md}) {
    top: 0;
    width: 134px;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 102px;
    top: 20px;
  }
`

const Diamond = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          parallax0: file(relativePath: { eq: "background/diamond.png" }) {
            childImageSharp {
              fluid(maxWidth: 580) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <ImgContainer>
          <Img
            alt="Goldenslot 100% Bonus Play slot games"
            fluid={data.parallax0.childImageSharp.fluid}
            fadeIn={false}
            loading="eager"
          />
        </ImgContainer>
      )}
    />
  )
}

export default Diamond
