import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import StarsHome from "./StarsHome"
import styled from "@emotion/styled"
import { breakpoints, color } from "../../../utils/style"

const FourImagesWrap = styled("div")`
  .title-block {
    margin: 9rem 0 1rem;
    position: relative;
  }
  h2 {
    font-size: 43px;
    line-height: 1.3;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  @media (max-width: ${breakpoints.md}) {
    h2 {
      font-size: 22px;
    }
  }  
`

const SlidesList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  
  .slide-item {
    flex-basis: 23.5%;
    background: ${color.transparentWhite1};
    border-radius: 10px;
    margin-bottom: 20px;
    &:hover, &:active {
      text-decoration: none;
      .more-cta {
        display: block;
      }
      
      .gatsby-image-wrapper {
        filter: brightness(30%);
        transition: .2s;
      }
    }
    
    .more-cta {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140px;
      line-height: 1.5;
    }
    
    .img-wrap {
      position: relative;
      border-radius: 10px 10px 0 0;
    }
    
    .gatsby-image-wrapper, img {
      border-radius: 10px 10px 0 0;
    }
    
    .text-wrap {
      padding: 10px 20px;
    }
    
    .h4 {
      color: ${color.gold};
      font-size: 16px;
      font-weight: 700;
    }
    
    .text {
      font-size: 14px;
      margin-top: 10px;
    }
    
    @media (max-width: ${breakpoints.md}) {
      flex-basis: 48%;
    }
    
    @media (max-width: ${breakpoints.xs}) {
      flex-basis: 100%;
    }   
  }
`

const FourImages = ({ h2, slides }) => {

  return (
    <FourImagesWrap>
      {h2 && <div className="title-block">
        <h2 className="h3">{h2}</h2>
        <StarsHome />
      </div>}
      {slides.length > 0 && (
        <SlidesList>
          {slides.map(item => (
            <a key={item.id} className="slide-item"
               href={
                 item.acf && item.acf.slide_url.indexOf("http") !== -1
                   ? item.acf.slide_url
                   : process.env.URL_CLEAN + item.acf.slide_url
               }>
              {item?.localFile?.childImageSharp?.fluid && (
                <div className="img-wrap">
                  <Img
                    fluid={item.localFile.childImageSharp.fluid}
                    alt={item.altText ? item.altText : "Golden Slot"}
                    fadeIn={false}
                    loading="eager"
                  />
                  <span className="more-cta button gradient-button">
                    อ่านต่อ
                  </span>
                </div>
              )}
              {item.acf?.slide_title &&
                <div className="text-wrap">
                  <div className="h4">{item.acf.slide_title}</div>
                  {item.acf?.slide_description &&
                    <div className="text"
                         dangerouslySetInnerHTML={{ __html: item.acf.slide_description }} />
                  }
                </div>
              }
            </a>
          ))}
        </SlidesList>
      )}
    </FourImagesWrap>
  )
}

export default FourImages

FourImages.propTypes = {
  h2: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      acf: PropTypes.shape({
        slide_description: PropTypes.string,
        slide_title: PropTypes.string,
        slide_url: PropTypes.string
      }),
      alt_text: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string
    })
  )
}
