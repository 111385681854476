import React from "react"
import styled from "@emotion/styled"
import { color, breakpoints, font, sizing } from "../../../utils/style"
import Step from "../../../icons/svg/Step"
import Deposit from "../../../icons/svg/Deposit"
import Register from "../../../icons/svg/Register"
import Enjoy from "../../../icons/svg/Enjoy"

const WrapperSection = styled("div")`
  margin: 30px 0;
  height: 120px;
  border-radius: 20px;
  position: relative;
  background-color: rgba(79, 83, 80, .3);
  .step-block {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    &:hover,
    &:active,
    &:focus {
      color: ${color.white};
      text-decoration: none;
      text-shadow: 0 0 9px ${color.peach};
      .step-title {
        color: ${color.white};
      }
    }
  }
  .step-title {
    display: block;
    font-weight: ${font.mediumWeight};
    margin-bottom: 10px;
    color: ${color.gold};
    text-shadow: 0 0 5px ${color.lightBrown}, 0 1px 4px ${color.darkBrown};
    text-transform: uppercase;
  }
  .step-details {
    opacity: .8;
  }
  .content-wrap {
    display: flex;
    align-items: center;
    height: 100%;
    svg {
      pointer-events: none;
    }
  }
  .step-icon {
    position: absolute;
  }
  .step-icon-1 {
    display: none;
  }
  .step-icon-2,
  .step-icon-3 {
    right: 108%;
  }
  .text-block {
    position: relative;
    z-index: 1;
  }
  @media (min-width: ${breakpoints.mdMin}) {
    .step-icon-1 {
      animation: run linear infinite 5s;
      display: block;
    }
    @keyframes run {
      0% {
        left: -50%;
      }
      100% {
        left: 280%;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: static;
    height: auto;
    overflow: hidden;
    .step-block {
      position: relative;
      left: 0;
      top: auto;
      display: flex;
      width: 100%;
      height: 120px;
    }
    .step-icon {
      right: auto;
      left: -70px;
      display: block;
    }
    .content-wrap {
      svg {
        width: 80px !important;
        margin: 0 60px 0 10px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    border-radius: 10px;
    margin: 10px 0;
    .step-block {
      height: 82.33px;
    }
    .step-icon {
      left: -80px;
      svg {
        height: 75px;
      }
    }
    .content-wrap {
      height: 82.33px;
      svg {
        width: 57px !important;
        margin: 0 33px 0 10px !important;
      }
      .text-block {
        width: calc(100% - 50px);
        margin: 10px 0;
      }
    }
    .step-title {
      font-size: 14px;
      margin: 5px 0 0;
    }
    .step-details {
      font-size: ${sizing.text};
      display: block;
      width: 92%;
      line-height: 1.48;
    }
  }
`

const Step1Wrap = styled("a")`
  width: 28%;
  left: 3%;
  .content-wrap {
    svg {
      margin: 0 20px 0 -10px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 0;
    .content-wrap {
      svg {
        width: 70px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .content-wrap {
      svg {
        width: 63px !important;
      }
    }
  }
`

const Step2Wrap = styled("a")`
  width: 30%;
  top: 10px;
  left: 35%;
  padding: 0 3%;
  cursor: pointer;
  .content-wrap {
    svg {
      margin: 0 20px 0 -40px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
  @media (max-width: ${breakpoints.sm}) {
    .content-wrap {
      svg {
        width: 70px !important;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .content-wrap {
      svg {
        width: 54px !important;
      }
    }
  }
`

const Step3Wrap = styled("a")`
  width: 28%;
  top: 10px;
  left: 69%;
  .content-wrap {
    svg {
      margin: 0 20px 0 0;
    }
  }
`

const Steps = () => (
  <WrapperSection>
    <Step1Wrap className="step-block" href="/register/?register_type=onsitegames">
      <div className="step-icon step-icon-1">
        <Step id="step-icon-1" />
      </div>
      <div className="content-wrap content-wrap-step-1">
        <Register width={57} height={60} />
        <div className="text-block">
          <span className="step-title">1. สมัครสมาชิก</span>
          <span className="step-details">แอดไลน์ที่ {process.env.LINE_ID_TEXT} <br/> หรือโทร 025 088 798</span>
        </div>
      </div>
    </Step1Wrap>
    <Step2Wrap className="step-block" href={process.env.LINE_ID_EXT}>
      <div className="step-icon step-icon-2">
        <Step id="step-icon-2" />
      </div>
      <div className="content-wrap">
        <Deposit width={95.1} height={60} />
        <div className="text-block">
          <span className="step-title">2. ฝากเงิน</span>
          <span className="step-details">
          ติดต่อฝ่ายบริการลูกค้า เพื่อทำรายการฝากเงินเข้าบัญชี
          </span>
        </div>
      </div>
    </Step2Wrap>
    <Step3Wrap className="step-block no-desktop-arrow" href="/register/?register_type=onsitegames">
      <div className="step-icon step-icon-3">
        <Step id="step-icon-3" />
      </div>
      <div className="content-wrap no-desktop-arrow content-wrap-step-3">
        <Enjoy width={107.98} height={55} />
        <div className="text-block">
          <span className="step-title">3. สนุกกับเกมได้เลย</span>
          <span className="step-details">
          รับยูซเซอร์และรหัสผ่าน เพื่อเข้าสู่ระบบและสนุกกับต่างๆ ทันที
          </span>
        </div>
      </div>
    </Step3Wrap>
  </WrapperSection>
)

export default Steps
