const ScrollTo = el => {
  const top = el.getBoundingClientRect().top + window.pageYOffset;
  const headerHeight = document.getElementById('app-header').offsetHeight;

  window.scrollTo({
    top: top - headerHeight,
    behavior: 'smooth',
  });
};

export default ScrollTo;
