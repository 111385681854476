import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Diamond from "./Diamond"
import { breakpoints, color } from "../../../utils/style"

const TypeOfGameWrap = styled("div")`
  position: relative;
  padding-top: 70px;

  h2 {
    font-size: 43px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-top: 50px;
    
    h2 {
      font-size: 22px;
    }
  }  
`

const GamesWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .game-type-link {
    width: 390px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin: 0 0 24px;

    .game-type-image {
      border-radius: 8px;
      filter: brightness(0.4);
      transition: 0.8s;
    }

    &:hover {
      .game-type-icon {
        transform: rotate(360deg);
        transition: 1s;
      }

      .game-type-image {
        transition: 0.8s;
        filter: brightness(1);
        transform: scale(1.1);
      }
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    .game-type-link {
      width: 48%;
    }
  }
`

const GamesLabel = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${color.gold};

  .game-type-icon {
    width: 60px;
  }

  @media (max-width: ${breakpoints.sm}) {
    .game-type-icon {
      width: 40px;
    }
  }
`

const TypeOfGame = ({ typesList, iconsList }) => {
  return (
    <TypeOfGameWrap>
      <Diamond />
      <h2 className="h3">คาสิโนออนไลน์ ยอดนิยม เล่นฟรีที่</h2>
      <GamesWrapper>
        {typesList.map((item, index) => (
          <Link
            to={item.acf.slide_url}
            className="game-type-link"
            key={item.id}
          >
            <Img
              fluid={item.localFile.childImageSharp.fluid}
              alt={item.altText ? item.altText : "Gclub"}
              fadeIn={false}
              loading="eager"
              className="game-type-image"
            />
            <GamesLabel>
              <Img
                fluid={iconsList[index].localFile.childImageSharp.fluid}
                alt={
                  iconsList[index].alt_text ? iconsList[index].altText : "Gclub"
                }
                fadeIn={false}
                loading="eager"
                className="game-type-icon"
              />
              <span>{iconsList[index].alt_text}</span>
            </GamesLabel>
          </Link>
        ))}
      </GamesWrapper>
    </TypeOfGameWrap>
  )
}

export default TypeOfGame
