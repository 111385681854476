import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';

const ImgContainer = styled('div')`
  width: 154px;
  position: relative;
  z-index: -1;
  @media (max-width: ${breakpoints.xs}) {
    width: 102px;
  }
`;

const ChooseGameImg = () => {
  return(
  <StaticQuery
    query=
      {graphql`
        query {
          cards: file(relativePath: { eq: "background/cherries.png" }) {
            childImageSharp {
              fluid(maxWidth: 154) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
    render={data => (
      <ImgContainer className="choose-game-img">
        <Img alt="Choose your way to play" fluid={data.cards.childImageSharp.fluid} fadeIn={false} loading='eager' />
      </ImgContainer>
    )}
  />
)};

export default ChooseGameImg;