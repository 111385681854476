import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import IcomoonIcon from "../../shared/IcomoonIcon"
import PopupWindow, { invokePopup } from "../../shared/PopupWindow"
import styled from "@emotion/styled"
import { breakpoints, color } from "../../../utils/style"
import StoreContext from "../../shared/Context/StoreContext"
import SlotGamesTimer from "./SlotGamesTimer"
import StarsHome from "./StarsHome"

const DemoGamesWrap = styled("div")`
  .title-block {
    margin: 8rem 0 1rem;
    position: relative;
  }
  .h3 {
    text-align: center;
    font-size: 43px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    line-height: 48px;
  }
  .mobile-rotate {
    display: none;
    text-align: center;
  }
  .waiting {
    position: relative;
    .h3 {
      padding: 0;
      text-align: center;
      max-width: 100%;
      margin-bottom: 5%;
    }
    .bottom-buttons {
      margin-top: 5%;
      .button {
        margin: 10px;
      }
    }
  }
  .login-register-prompt {
    position: absolute;
    top: 28px;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px 7%;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }

  @media (max-width: ${breakpoints.md}) {
    .h3 {
      margin: 10px 3px 10px 0;
      font-size: 24px;
    }
    .mobile-rotate {
      display: block;
      position: absolute;
      top: 30%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
    .mobile-rotate + .iframe-container,
    .login-register-prompt {
      opacity: 0;
      visibility: hidden;
    }
    .waiting {
      .timer-wrap,
      .h3 {
        position: static;
      }
    }
    .login-register-prompt {
      top: 0;
    }
    .iframe-container {
      width: auto;
      position: static;
      overflow: auto;
      padding: 0;
      iframe {
        position: static;
        height: 98vh;
      }
    }

    .slick-arrow {
      visibility: hidden;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .h3 {
      font-size: 22px;
    }
  }

  @media (orientation: landscape) {
    .mobile-rotate {
      display: none;
    }
    .mobile-rotate + .iframe-container,
    .login-register-prompt {
      opacity: 1;
      visibility: visible;
      
      .h3 {
        left: 50%;
        transform: translateX(0);
      }
    }
  }
`

const GamesList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto;
  
  .demo-game-item {
    width: 225px;
    position: relative;
    margin: 0 0 23px;
    display: inline-block;
    padding: 0;    
    height: 100%;
    
    &:hover {     
      .play-cta {
        display: block;
      }
      
      .gatsby-image-wrapper {
        filter: brightness(30%);
        transition: .2s;
      }
    }
    
    .play-cta {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140px;
    }
    
    .gatsby-image-wrapper {
      border-radius: 10px;
    }    
    
    @media (max-width: ${breakpoints.lg}) {
      width: 19%;
    }
    
    @media (max-width: ${breakpoints.md}) {
      width: 32%;
    }
    
    @media (max-width: ${breakpoints.sm}) {
      width: 48%;
    }   
  }
`

const Demo15Games = ({ demoGameUrl, slotSlides }) => {
  const [timerState, setTimerState] = useState({
    startTimer: false
  })

  const demoClickHandler = (e, popupId, gameTitle, gameLink) => {
    invokePopup(e, popupId)
    const gameIframe = document.getElementById("game-home-iframe")
    gameIframe.src = gameLink || demoGameUrl
    const gamePopupTitle = document.getElementById(popupId + "-title")
    if (gamePopupTitle && gameTitle) {
      gamePopupTitle.innerHTML = gameTitle
    }
    setTimerState({
      startTimer: true
    })
  }

  const storeValue = useContext(StoreContext)
  const username = storeValue.username

  return (
    <DemoGamesWrap>
      <div className="title-block">
        <h2 className="h3">ทดลองเล่นฟรี 15 เกมสล็อตออนไลน์</h2>
        <StarsHome/>
      </div>

      {slotSlides.length > 0 && (
        <GamesList>
          {slotSlides.map(item => (
            <a className="demo-game-item"
               onClick={e =>
                 demoClickHandler(
                   e,
                   "demo-home-popup",
                   item.alt_text,
                   item.acf.slide_url
                 )
               }
               href={
                 item.acf && item.acf.slide_url.indexOf("http") !== -1
                   ? item.acf.slide_url
                   : process.env.URL + item.acf.slide_url
               }
               key={item.id}>
              {item.localFile && item.localFile.childImageSharp.fluid && (
                <Img
                  fluid={item.localFile.childImageSharp.fluid}
                  alt={item.altText ? item.altText : "Gclub"}
                  fadeIn={false}
                  loading="eager"
                />
              )}
              <span className="play-cta button gradient-button">
                ทดลองเล่น
              </span>
            </a>
          ))}
        </GamesList>
      )}
      <PopupWindow
        title="เกมตัวอย่าง"
        popupId="demo-home-popup"
        extraClass="wide title-orange custom-game-popup"
      >
        {!username && timerState.startTimer && (
          <SlotGamesTimer startTime={300000} />
        )}
        <div className="mobile-rotate">
          <IcomoonIcon icon="mobile-rotate" color={color.orange} size={80} />
          <p>Please rotate your device</p>
        </div>
        <div className="iframe-container">
          <iframe title="Demo Game" id="game-home-iframe" src="" />
        </div>
      </PopupWindow>
    </DemoGamesWrap>
  )
}

export default Demo15Games

Demo15Games.propTypes = {
  demoGameUrl: PropTypes.string,
  slotSlides: PropTypes.arrayOf(
    PropTypes.shape({
      acf: PropTypes.shape({
        slide_description: PropTypes.string,
        slide_title: PropTypes.string,
        slide_url: PropTypes.string
      }),
      alt_text: PropTypes.string,
      id: PropTypes.string,
      title: PropTypes.string
    })
  )
}

Demo15Games.defaultProps = {
  demoGameUrl:
    "https://showcase.playngo.com/Casino/IframedView?pid=2&gid=theswordandthegrail&gameId=376&lang=th&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://showcase.playngo.com/&showpoweredby=True"
}
