import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';

const ImgContainer = styled('div')`
  position: absolute;
  width: 236px;
  left: 50%;
  top: -100px;
  z-index: -1;
  opacity: .9;
  transform: translateX(-50%);
  @media (max-width: ${breakpoints.md}) {
    width: 134px;
    top: -80px;
  }
`;

const StarsHome = () => {
  return(
  <StaticQuery
    query=
      {graphql`
        query {
          parallax0: file(relativePath: { eq: "background/bitmap.png" }) {
            childImageSharp {
              fluid(maxWidth: 580) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
    render={data => (
        <ImgContainer>
          <Img alt="Goldenslot 100% Bonus Play slot games" fluid={data.parallax0.childImageSharp.fluid} fadeIn={false} loading='eager' />
        </ImgContainer>
    )}
  />
)};

export default StarsHome;
